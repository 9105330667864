$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionTwo {
	background-color: var(--black);
	&__top {
		padding: 120px 5% 0px;
		@media screen and (max-width: $breakpoint-tablet) {
			padding-top: 70px;
		}
	}
	&__fleet {
		&__list {
			padding: 30px 5%;
			&__car {
				width: 50%;
				padding: 0px 0px 4px 8px;
				span {
					font-family: "Aeonik";
					font-size: 1.1rem;
				}
			}
		}
	}
}
