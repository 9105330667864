$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionSix {
	padding: 120px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	&__top {
		justify-content: space-between;
		width: 90%;
		margin-bottom: 70px;
		&__heading {
			width: 55%;
		}
		&__paragraph {
			width: 40%;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			display: flex;
			flex-direction: column;
			&__paragraph {
				width: 100%;
				margin-top: 30px;
			}
		}
	}
	&__bottom {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 120px;
		@media screen and (max-width: $breakpoint-tablet) {
			display: flex;
			flex-direction: column;
		}
	}
}
