$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.metricTemplate {
	margin: 50px 30px 40px 0px;
	@media screen and (max-width: $breakpoint-tablet) {
		margin: 10px 0px 20px;
	}
	&__value {
		margin-bottom: 5px;
		font-size: 2.3rem;
		font-family: "integralcf";
		span {
			font-family: "Inter";
			font-weight: 900;
		}
	}
	&__bottom {
		p {
			text-transform: capitalize;
			margin-left: 15px;
			font-family: "Aeonik";
		}
	}
}
