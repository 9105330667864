$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.specCard {
	margin-bottom: 20px;
	&__value {
		color: var(--foundation-normal);
		font-size: 2rem;
		font-family: "integralcf";
	}
	&__spec {
		margin-top: 0px;
		text-transform: capitalize;
	}
}
