$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.heading {
	font-size: 4.2vw;
	text-transform: uppercase;
	line-height: 1.2;
	font-family: "integralcf";
	span {
		color: var(--foundation-normal);
	}
	@media screen and (max-width: $breakpoint-tablet) {
		font-size: 2.3rem;
		width: 100% !important;
	}
	@media screen and (max-width: $breakpoint-bigPhone) {
		font-size: 1.5rem;
		width: 100% !important;
	}
}
