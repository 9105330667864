$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.navbar {
	padding: 26px 0px 0px;
	justify-content: space-between;
	&__navigation {
		justify-content: space-around;
		width: 30%;
		a {
			color: var(--white);
			font-family: "Aeonik-light";
			font-size: 0.9rem;
			&:hover {
				color: var(--foundation-normal);
				transition: 1s;
				border-bottom: 1px solid var(--foundation-normal);
			}
		}
	}
	&__partitionLine {
		border: 1px solid rgba(255, 255, 255, 0.6);
		width: 25%;
	}
}
.activeNav {
	color: var(--foundation-normal);
	transition: 1s;
	border-bottom: 1px solid var(--foundation-normal);
}

.navbarMobile {
	display: none;
}
.container {
	display: inline-block;
	cursor: pointer;
	.bar1,
	.bar2,
	.bar3 {
		width: 30px;
		height: 2px;
		background-color: white;
		margin: 4px 0;
		transition: 0.4s;
	}
}

.change .bar1 {
	transform: translate(0, 7px) rotate(-45deg);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	transform: translate(0, -5px) rotate(45deg);
}
.button {
	background-color: var(--foundation-normal);
	padding: 25px;
	width: fit-content;
	border-radius: 6px;
}
@media screen and (max-width: $breakpoint-desktop) {
	.navbar {
		display: none;
	}
	.navbarMobile {
		display: flex;
		padding: 26px 0px 0px;
		justify-content: space-between;
		&__partitionLine {
			border: 1px solid rgba(255, 255, 255, 0.6);
			width: 70%;
			align-self: center;
		}
		&__navigation {
			background-color: var(--foundation-normal);
			flex-direction: column;
			margin-bottom: 50px;
			padding: 30px 0px;

			a {
				color: var(--white);
				margin: 15px 0px;
				font-size: 1.3rem;
			}
		}
	}
}
