.btn {
	padding: 12px 22px;
	border: none;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	align-items: center;
	transition: 0.3s;
	border: 1px solid;
	border-radius: 4px;
	justify-content: center;
	width: fit-content;
	text-transform: capitalize;
	font-family: "Aeonik";
}
.fill {
	color: var(--white);
	background: var(--foundation-normal);
	border-color: transparent;

	&:hover {
		background: #4b6824;
	}

	&:active {
		background: var(--brand-pressed);
		border-color: var(--brand-pressed);
	}
}
.blackOutline {
	border: 1px solid var(--foundation-black-500);
	color: var(--foundation-black-500);
	background: var(--white);
}
.whiteOutline {
	border: 1px solid var(--white);
	color: var(--white);
	background: transparent;
}
.btn:disabled {
	cursor: not-allowed;
	opacity: 0.6;
}
