$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.sectionEight {
	padding: 120px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	&__top {
		margin-bottom: 60px;
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
			align-items: flex-start;
		}
		&__heading {
			width: 65%;
			@media screen and (max-width: $breakpoint-tablet) {
				margin-bottom: 20px;
			}
		}
		&__paragraph {
			width: 30%;
		}
	}
	&__bottom {
		width: 90%;
		justify-content: space-between;
		margin: auto;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}
		&__reviewQuotes {
			width: 45%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
		}
		&__imageContainer {
			width: 45%;
			img {
				width: 100%;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				margin-top: 40px;
				width: 80%;
			}
		}
	}
}
