$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.sectionNine {
	padding: 120px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	&__top {
		margin-bottom: 70px;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
			margin-bottom: 20px;
		}
		&__heading {
			@media screen and (max-width: $breakpoint-tablet) {
				margin-bottom: 20px;
			}
		}
		&__paragraph {
			width: 30%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
		}
	}
	&__bottom {
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column-reverse;
		}
		&__imageContainer {
			width: 70%;
			img {
				width: 100%;
			}
		}
	}
}
