*,
::before,
::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

:root {
	/*Grey Colors*/
	--grey-700: #767676;
	--grey-500: #a6a6a6;

	/* Text Colors */
	--text-main: #1e354e;

	--foundation-normal: #8ac440;
	--foundation-light: #f3f9ec;
	--foundation-black-300: #5d5a59;
	--foundation-black-500: #0d0907;
	--white: #ffffff;
	--black: #020202;
}
.row0 {
	display: flex;
	flex-direction: row;
}
.row1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.row2 {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.column0 {
	display: flex;
	flex-direction: column;
}
.column1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.column2 {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@font-face {
	font-family: "integralcf";
	src: url("../public/fonts/Integral-www.Dfonts.org/OTF/IntegralCF-DemiBold.otf")
		format("opentype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Aeonik";
	src: url("../public/fonts/Aeonik-font-download/Aeonik-Medium.otf")
		format("opentype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Aeonik-light";
	src: url("../public/fonts/Aeonik-font-download/Aeonik-Light.otf")
		format("opentype");
	font-weight: normal;
	font-style: normal;
}
