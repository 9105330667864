$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.featureCard {
	justify-content: space-between;

	@media screen and (max-width: $breakpoint-mediumDesktop) {
		margin: 20px 0px;
	}
	&__statement {
		text-transform: uppercase;
		font-size: 2.2rem;
		width: 75%;
		font-family: "integralcf";
		@media screen and (max-width: $breakpoint-mediumDesktop) {
			margin-left: 20px;
			font-size: 1.6rem;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 1.2rem;
		}
	}
}
