$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.sectionThree {
	background-color: var(--black);
	&__top {
		padding: 120px 5% 0px;
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-tablet) {
			padding-top: 70px;
			flex-direction: column;
		}
		&__heading {
			width: 55%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin-top: 20px;
			}
		}
		&__left {
			width: 40%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
			&__paragraph {
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}
}
