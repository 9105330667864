$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.reviewQuote {
	margin: 30px 0px;
	&__quote {
		margin-bottom: 15px;
	}
	&__name {
		font-family: "integralcf";
		text-transform: uppercase;
		font-size: 1.3vw;
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 1rem;
		}
	}
}
