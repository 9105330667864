$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.bookRideCard {
	&__step {
		font-family: "integralcf";
		text-transform: uppercase;
		margin: 20px 0px;
		font-size: 1.3vw;
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 1.2rem;
		}
		@media screen and (max-width: "400px") {
			font-size: 1rem;
		}
	}
	&__description {
		width: 70%;
		text-align: center;
	}
}
