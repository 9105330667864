$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.heroDiv {
	background-image: url("../../asset/images/satin-hero.jpg");
	background-size: cover;
	background-position: center center;
	min-height: 958px;
	position: relative;
	height: 122vh;
	padding: 0px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		min-height: 400;
		height: 100vh;
	}
	&__content {
		width: 50%;
		margin-top: 90px;
		@media screen and (max-width: "670px") {
			margin-top: 50px;
			width: 100%;
		}
		&__heading {
			margin: 20px 0px;
		}
		&__paragraph {
			width: 80%;
			margin-bottom: 20px;
			@media screen and (max-width: "670px") {
				width: 100%;
			}
		}
	}
}
