$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.tag {
	border-radius: 20px;
	border: 1px solid var(--foundation-normal);
	width: fit-content;
	padding: 7px 15px;
	p {
		color: var(--foundation-normal);
		text-transform: capitalize;
		margin-left: 7px;
		font-family: "Aeonik";
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 0.8rem;
		}
	}
}
