$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.footerSection {
	padding: 120px 5% 0px;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
	}
	&__top {
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
			align-items: flex-start !important;
		}
		&__heading {
			width: 30%;
		}
		&__paragraph {
			font-size: 13px;
			color: var(--foundation-black-500) !important;
			width: 25%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin: 30px 0px 10px;
			}
		}
		&__navigation {
			justify-content: space-between;
			width: 27%;
			@media screen and (max-width: $breakpoint-tablet) {
				flex-direction: column;
				align-items: flex-start !important;
				width: fit-content;
			}
			a {
				font-family: "Aeonik";
				font-size: 15px;
				@media screen and (max-width: $breakpoint-tablet) {
					margin: 20px 0px;
				}
			}
		}
	}
	&__logo {
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-bigPhone) {
			flex-direction: column;
			align-items: flex-start !important;
		}
		margin: 40px 0px 50px;
		&__socials {
			width: 20%;
			justify-content: space-between;
			@media screen and (max-width: $breakpoint-bigPhone) {
				margin-bottom: 20px;
				width: 80%;
			}
		}
		&__satin {
			width: 3%;
			img {
				width: 100%;
			}
		}
	}
	&__horizontalLine {
		border-top: 1px solid var(--foundation-black-500);
	}
	&__bottom {
		margin: 50px 0px;
		&__paragraph {
			text-transform: capitalize;
			color: var(--foundation-black-500) !important;
		}
	}
	hr {
		color: var(--foundation-black-500);
	}
}
