$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.sectionTen {
	&__top {
		padding: 120px 5% 30px;
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-tablet) {
			padding-top: 70px;
			flex-direction: column;
		}
		&__heading {
			width: 100%;
		}
		&__right {
			width: 90%;
			&__paragraph {
				width: 100%;
				margin-bottom: 20px;
				@media screen and (max-width: $breakpoint-tablet) {
					margin-top: 40px;
				}
			}
		}
	}
}
