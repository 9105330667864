$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionOne {
	position: relative;
	background-color: var(--black);
	padding: 120px 0px;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px 0px;
	}
	&__top {
		padding: 100px 5%;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}
		&__heading {
			width: 60%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
		}
		&__paragraph {
			width: 40%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin-top: 20px;
			}
			&__p {
				width: 45%;
				@media screen and (max-width: $breakpoint-tablet) {
					width: 100%;
				}
			}
		}
	}
	.car {
		position: relative;
		img {
			width: 100%;
		}
	}
}
.requestRideModalContainer {
	position: absolute;
	bottom: 10%;
	width: 100%;
	display: flex;
	justify-content: center;
	&__requestRideModal {
		padding: 40px;
		border-radius: 9px;
		width: 90%;
		background-color: var(--black);
		&__top {
			h3 {
				text-transform: uppercase;
				color: var(--white);
				font-size: 1.9rem;
				font-family: "IntegralCF";
			}
			margin-bottom: 30px;
		}
		&__mid {
			align-items: flex-end;
			justify-content: space-between;
			&__form {
				width: 75%;
				justify-content: space-between;
				> div {
					width: 49%;
					label {
						text-transform: capitalize;
						color: var(--white);
						margin-bottom: 7px;
						font-size: 1.1rem;
					}
					input {
						border: 1px solid grey;
						border-radius: 4px;
						padding: 10px 20px;
						background-color: transparent;
						text-transform: capitalize;
						color: var(--grey-500);
					}
				}
			}
			.bookRideButton {
				width: 23%;
				font-family: "Aeonik-light";
			}
		}
		&__bottom {
			margin-top: 15px;
			justify-content: flex-end;
			a {
				text-transform: capitalize;
				color: var(--foundation-normal);
				font-family: "Aeonik-light";
				border-bottom: 1px solid var(--foundation-normal);
			}
		}
	}
	@media screen and (max-width: $breakpoint-desktop) {
		&__requestRideModal {
			width: 100%;
			border-radius: 0px;
		}
	}
	@media screen and (max-width: 850px) {
		&__requestRideModal {
			width: 100%;
			border-radius: 0px;
			align-items: center;
			&__top {
				h3 {
					font-size: 1.3rem;
				}
			}
			&__mid {
				width: 100%;
				flex-direction: column;
				align-items: center;
				&__form {
					flex-direction: column;
					width: 90%;
					> div {
						width: 100%;
					}
				}
				.bookRideButton {
					margin-top: 25px;
					width: 90%;
				}
			}
			&__bottom {
			}
		}
	}
}
