$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.faqContainer {
	padding-top: 1.25rem;
	margin-bottom: 3rem;
	width: 100%;
}

.faqList {
	max-width: 80%;
	margin: auto;
	@media screen and (max-width: $breakpoint-tablet) {
		max-width: 100%;
	}
}
.faqItem {
	margin-bottom: 0px;
	border-bottom: 1px solid var(--foundation-black-500);
}
.faqBtn {
	width: 100%;
	text-align: left;
	padding: 1rem 0;
	background: transparent;
	color: var(--text-main);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border: none;
	cursor: pointer;
	font-weight: 500;

	* {
		transition: 0.3s;
	}

	svg {
		min-width: 32px;
	}
}
.faqBtn span {
	width: 80%;
	line-height: 1.5;
	color: var(--foundation-black-500);
	font-family: "Aeonik";
	font-size: 16px;
	@media screen and (max-width: $breakpoint-bigPhone) {
		font-size: 0.9rem;
	}
}
.faqBody {
	font-size: 14px;
	width: 95%;
	overflow: hidden;
	height: 0;
	transition: height 0.3s ease-out;
	color: var(--grey-2);
	line-height: 1.5;
	max-width: 520px;
}
.activeItem .faqBody {
	height: auto;
}
.inactiveItem .faqBody {
	animation-name: hideFaq;
}
.faqBody div {
	margin-bottom: 1.2rem;
	color: var(--foundation-black-300);
	font-family: "Aeonik";
}

@media screen and (max-width: 600px) {
	.ttl {
		font-size: var(--heading-3);
		text-align: left;
	}
	.txt {
		text-align: left;
		max-width: 340px;
		margin-bottom: 2rem;

		a {
			font-weight: inherit;
		}
	}
}
