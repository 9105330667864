$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionSeven {
	padding: 120px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	&__top {
		justify-content: space-between;
		margin-bottom: 90px;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
			margin-bottom: 50px;
		}
		&__paragraph {
			width: 40%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin-bottom: 20px;
			}
		}
		&__heading {
			width: 55%;
		}
	}
	&__steps {
		.showMobile {
			display: none;
		}
		.showDesktop {
			display: block;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			.showMobile {
				display: block;
				margin: 60px 0px;
			}
			.showDesktop {
				display: none;
			}
		}
		&__top {
			width: 90%;
			justify-content: space-between;
			@media screen and (max-width: $breakpoint-tablet) {
				flex-direction: column;
			}
		}
		&__dottedCurvedDesktop {
			margin: 60px 0px;
			@media screen and (max-width: $breakpoint-tablet) {
				display: none;
			}
		}
		&__dottedCurvedMobile {
			display: none;
			@media screen and (max-width: $breakpoint-tablet) {
				display: block;
				margin: 60px 0px;
			}
		}
		&__bottom {
			width: 90%;
			justify-content: space-between;
			@media screen and (max-width: $breakpoint-tablet) {
				flex-direction: column;
			}
		}
	}
}
