$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionFour {
	background-color: white;
	padding: 100px 5%;
	&__top {
		&__heading {
			margin-bottom: 25px;
		}
		&__paragraph {
			width: 40%;
			@media screen and (max-width: "900px") {
				width: 100%;
			}
		}
	}
	&__bottom {
		justify-content: space-between;
		@media screen and (max-width: "900px") {
			flex-direction: column;
			align-items: center;
		}
		&__left {
			margin-top: 120px;
			justify-content: space-between;
			width: 45%;
			@media screen and (max-width: "900px") {
				width: 100%;
				margin: 40px 0px;
			}
		}

		&__right {
			width: 50%;
			@media screen and (max-width: "900px") {
				width: 80%;
			}

			img {
				width: 75%;
				@media screen and (max-width: $breakpoint-mediumDesktop) {
					width: 100%;
				}
			}
		}
	}
}
