$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.bookingButtons {
	p {
		margin-left: 8px;
	}
	&__whatsapp {
		margin-right: 20px;
	}
	&__webchat {
	}
	@media screen and (max-width: $breakpoint-mediumDesktop) {
		flex-direction: column;
		align-items: flex-start;
		&__whatsapp {
			width: 100%;
			margin-bottom: 15px;
		}
		&__webchat {
			width: 100%;
		}
	}
}
