$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.carDetails {
	position: relative;

	&__info {
		position: absolute;
		color: var(--white);
		padding: 0px 5%;
		width: 100%;
		&__name {
			font-family: "integralcf";
			margin-bottom: 30px;
			font-size: 2.9rem;
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 1.3rem;
			}
		}
		&__specs {
			width: 60%;
			justify-content: space-between;
			flex-wrap: wrap;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
		}
	}
	img {
		width: 100%;
	}
}
