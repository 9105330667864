$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionFive {
	padding: 120px 5%;
	@media screen and (max-width: $breakpoint-tablet) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.about {
		text-transform: uppercase;
		font-family: "Aeonik";
		color: var(--foundation-black-300);
	}
	&__heading {
		margin: 30px 0px 60px;
	}
	&__bottom {
		justify-content: space-between;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}
		&__left {
			width: 50%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
		&__right {
			width: 45%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin-top: 20px;
			}
			&__paragraph {
				width: 80%;
				@media screen and (max-width: $breakpoint-tablet) {
					width: 100%;
				}
			}
			&__metric {
			}
			&__bookingButtons {
				width: 85%;
				@media screen and (max-width: $breakpoint-tablet) {
					width: 100%;
				}
			}
		}
	}
}
