$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.whyChooseUsCard {
	width: 100%;
	&__statement {
		text-transform: uppercase;
		font-size: 2.2rem;
		font-family: "integralcf";
		margin: 18px 0px;
	}
}
